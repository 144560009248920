require("@rails/ujs").start()
// require("channels")

import './src_home/bootstrap_config';
import './src_home/init_stickybits';
import './src_home/init_btn_whatsapp';
import 'jquery.easing'

import { init_slider } from './src_home/init_slider';
import { init_aos } from './src_home/init_aos';
import { init_navbar_mobile } from './src_home/init_navbar_mobile';
import { init_typed } from './src_home/init_typed';

init_slider();
init_aos();
init_navbar_mobile();
init_typed();
