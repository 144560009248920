import Typed from 'typed.js';

const init_typed = () => {

  // keyword está em app/views/pages/_intro.html.haml
  const keyword = document.querySelector('.intro-content h1 span')
  keyword.innerText = ''

  var typed = new Typed(keyword, {
    strings: ["Ativação", "Vendas", "Campo"],
    typeSpeed: 60,
    backSpeed: 30,
    backDelay: 5000,
    loop: true,
    showCursor: false
  });

}

export { init_typed }
